.maskedKey {
    cursor: pointer;
    user-select: none;
    transition: all 0.3s ease;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.maskedKey:hover,
.maskedKey.revealed {
    color: #007bff; /* Optional: change color when revealed */
}

@media (hover: hover) {
    .maskedKey:hover {
        color: #0056b3; /* Slightly darker blue on hover */
    }
}

.copyTopIcon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin-right: 4px;
}
