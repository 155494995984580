.container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: var(--footer-height);
    z-index: 99;
    background-color: var(--trades-history-table-bg);
}

.table_wrap {
    position: relative;
    height: calc(100% - 210px);
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    align-items: center;
    background: #101115;
    color: #fff;
    padding: 12px 24px;

    .closeIcon {
        position: absolute;
        top: 5px;
        right: 12px;
    }

    :global(.MuiButtonBase-root) {
        &:hover {
            background-color: transparent;
            color: #a7aebb;
        }
    }

    :global(.MuiSvgIcon-root) {
        font-size: 22px;
    }

    .closeIcon_light {
        &::before {
            margin: auto;
            height: 25px;
            width: 25px;
            content: var(--asset-list-order-x-url);
            filter: invert(95%) sepia(8%) saturate(142%) hue-rotate(166deg) brightness(98%) contrast(94%);
        }
    }
}

.title {
    font-size: var(--desk-font-size-1-8);
    font-weight: 600;
    line-height: 130%;
}

.filters {
    display: flex;
    padding: 20px 8px 40px 8px;
}

.periodFilterList {
    display: flex;
    gap: 8px;
    align-items: center;
}

.periodFilterListItem_dark,
.periodFilterListItem_light {
    background: var(--trades-history-table-filter-period-list-item-bg);
    color: var(--trades-history-table-filter-period-list-item-color);
    padding: 4px 8px;
    border-radius: 20px;
    font-weight: 600;
    font-size: var(--desk-font-size-1-3);
    line-height: 16px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background: var(--trades-history-table-filter-period-list-item-bg-hover);
        color: var(--trades-history-table-filter-period-list-item-color-hover);
    }
}

.periodFilterListItem_light {
    &:hover {
        background: var(--trades-history-table-filter-period-list-item-bg-light);
        color: var(--trades-history-table-filter-period-list-item-bg-hover);
    }
}

.periodFilterListItemActive {
    background: var(--trades-history-table-filter-period-list-item-bg-active) !important;
    color: var(--trades-history-table-filter-period-list-item-color-active) !important;
    cursor: default;
}

.customPeriodWrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 64px;
    align-items: center;
    gap: 8px;
}

.submit_button_dark,
.submit_button_light {
    text-transform: uppercase;
    font-weight: 700;
    font-size: var(--desk-font-size-1-3);
    text-align: center;
    height: 26px;
    width: fit-content;
    min-width: 59px;
    color: #e6ecf1;
    border: 1px solid #89909c;
    background: transparent;
    border-radius: 4px;
    cursor: pointer;
    padding: 4px 10px;

    &:hover {
        background-color: #444854;
    }
}

.submit_button_light {
    color: #343841;
    border: 1px solid #343841;

    &:hover {
        background-color: #343841;
        color: #e6ecf1;
    }
}

.header_profit_calc {
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, 0);
    left: calc(50% - 6px);
    bottom: 34px;
    z-index: 10;
    font-weight: 700;
    font-size: var(--desk-font-size-1-5);
    line-height: 130%;
    border-radius: 6px;
    padding: 5px 8px;
    text-align: center;
    text-transform: uppercase;
    background: #444854;
    color: #a7aebb;

    @media (max-width: 1300px) {
        font-size: var(--desk-font-size-1-3);
    }

    @media (max-width: 1100px) {
        font-size: var(--desk-font-size-1-1);
        padding: 4px 6px;
    }

    &:after {
        content: "";
        width: 8px;
        height: 8px;
        background: #89909c;
        position: absolute;
        left: 50%;
        top: 100%;
        margin-top: -4px;
        transform: rotateZ(45deg);
        border-radius: 2px;
    }

    &.header_profit_calc_profit {
        white-space: nowrap;
        display: flex;
        background: var(--profit);
        color: #fff;

        &:after {
            background: var(--profit);
        }
    }

    &.header_profit_calc_loss {
        white-space: nowrap;
        display: flex;
        background: var(--loss);
        color: #fff;

        &:after {
            background: var(--loss);
        }
    }
}

.table_header {
    border-bottom: 1px solid var(--trades-history-table-header-row-border-color);
    height: 36px;

    @media (max-width: 1300px) {
        height: auto;
    }

    .table_header_row {
    }

    .table_header_cell {
        position: relative;
        display: flex;
        min-width: 90px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: var(--desk-font-size-1-2);
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        color: var(--trades-history-header-table-color);
        padding: 8px 12px;

        .header_label {
            &:hover {
                cursor: pointer;
            }
        }

        @media (max-width: 1300px) {
            font-size: var(--desk-font-size-1-1);
            padding: 8px 8px;
        }

        @media (max-width: 1100px) {
            font-size: var(--desk-font-size-1);
            padding: 8px 0;
            min-width: 0;
        }
    }
}

.table_container {
    height: calc(100% - 51px);

    .table_body_row {
        align-items: center;
        justify-items: center;
        border-bottom: 1px solid var(--trades-history-table-row-border-bg);

        &:hover {
            background: var(--trades-history-table-body-row-hover-bg);
        }
    }

    .table_body_cell {
        position: relative;
        min-width: 90px;
        height: 40px;
        padding: 0 12px;
        font-weight: 700;
        font-size: var(--desk-font-size-1-5);
        line-height: 130%;
        text-align: center;
        color: var(--trades-history-table-color);

        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            max-width: 120px;
        }

        @media (max-width: 1300px) {
            font-size: var(--desk-font-size-1-1);
            padding: 0 8px;
        }

        @media (max-width: 1100px) {
            font-size: var(--desk-font-size-1-1);
            padding: 0;
            min-width: 0;
        }
    }
    .longtextAlignment {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: var(--desk-font-size-1-4);
    }

    .table_item_cell_time_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 90px;
    }

    .table_item_cell_date {
        display: flex;
        flex-direction: column;
        font-weight: 700;
        font-size: var(--desk-font-size-1-5);
        line-height: 130%;

        @media (max-width: 1300px) {
            font-size: var(--desk-font-size-1-1);
        }

        @media (max-width: 1100px) {
            font-size: var(--desk-font-size-1-1);
        }
    }

    .table_item_cell_time {
        display: flex;
        align-items: center;
        font-size: var(--desk-font-size-1);
        font-weight: 700;

        @media (max-width: 1300px) {
            font-size: var(--desk-font-size-09);
        }

        @media (max-width: 1100px) {
            font-size: var(--desk-font-size-08);
        }

        img {
            width: 13px;
            margin-right: 2px;
        }
    }
}

.table_container .table_body_row,
.table_header .table_header_row {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    &.withV5 {
        grid-template-columns: repeat(13, 1fr);
    }
}

.pagination_container {
    display: flex;

    :global(.break) {
        cursor: pointer;
        margin: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--trades-history-pagination-dots-color);
        font-size: 13px;
    }
}

.pagination_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin: 0 4px;
    cursor: pointer;
    color: var(--trades-history-pagination-color);

    &:hover {
        border: 1px solid var(--trades-history-pagination-hover-border-color);
    }
}

.pagination_button_active {
    color: var(--trades-history-pagination-active-color);
    background: var(--trades-history-pagination-active-bg);
    border-color: var(--trades-history-pagination-active-bg);

    &:hover {
        border: none;
    }
}

.pagination_button_text {
    font-weight: 700;
    font-size: var(--desk-font-size-1-3);
    text-transform: uppercase;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination_next,
.pagination_previous {
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:global(.disabled) {
        cursor: default;
    }
}

.pagination_next {
    background-image: var(--trades-history-pagination-next-icon-url);

    &:global(.disabled) {
        background-image: var(--trades-history-pagination-next-disabled-icon-url);
    }
}

.pagination_previous {
    background-image: var(--trades-history-pagination-prev-icon-url);

    &:global(.disabled) {
        background-image: var(--trades-history-pagination-prev-disabled-icon-url);
    }
}

.pagination_link {
    width: 24px;
    height: 24px;
    display: flex;
}

.pagination_wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 60px;
    margin-top: 16px;
    align-items: center;
}

.downloadPDF {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 25px;
    right: 24px;
    padding: 7px 12px;
    font-weight: 600;
    font-size: var(--desk-font-size-1-4);
    line-height: 130%;
    border-radius: 4px;
    padding: 7px 12px;
    z-index: 5;
    gap: 8px;
    cursor: pointer;
    border: 1px solid var(--trades-history-download-pdf-btn-border);
    color: var(--trades-history-download-pdf-btn-color);

    .label {
        white-space: nowrap;
        width: 90%;
    }

    .icon {
        background: var(--trades-history-download-pdf-btn-icon) no-repeat center;
        height: 16px;
        min-width: 16px;
    }

    &:hover {
        background-repeat: no-repeat;
        background-color: var(--trades-history-download-pdf-btn-hover-bg);
        color: var(--trades-history-download-pdf-btn-hover-color);

        .icon {
            background: var(--trades-history-download-pdf-btn-hover-icon) no-repeat center;
        }
    }
}

.loader_wrp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 58px);
    width: 100%;
}

.calendarIcon {
    background-image: var(--trades-history-table-datepicker-icon-url) !important;
}

.inputWrapper {
    width: 116px !important;

    input {
        font-size: var(--desk-font-size-1-3) !important;
    }

    input:hover {
        color: var(--trades-history-table-filter-date-picker-color-hover) !important;
    }
}
