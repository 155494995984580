.emptyList {
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: var(--desk-font-size-2-8);
    line-height: 34px;
    text-align: center;
    color: #89909c;
    padding: 0 32px;

    .button {
        min-height: 45px;
        margin-top: 20px;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        background: var(--intro-first-step-button-bg);
        border: none;

        .contentBtn {
            display: flex;
            align-items: center;
            gap: 13px;

            .resetIcon {
                width: 21px;
                height: 21px;
                background: var(--asset-list-favorite-reset-list-icon-url) no-repeat center;
            }

            .titleBtn {
                font-size: var(--desk-font-size-1-5);
                font-weight: 600;
                color: var(--intro-first-step-button-color);
                display: flex;
                align-items: center;
            }
        }
    }

    &.expandedMarginBlock {
        height: calc(100vh - 274px);
    }
}

.symbolImagesCell {
    display: flex;
    align-items: center;

    @media screen and (max-width: 479px) {
        min-width: unset;
    }
}

// .symbolMainInfoImages {
//     margin-right: 6px;
//     white-space: nowrap;

//     &.symbolMainInfoImagesSingleImage {
//         width: 100%;
//         display: flex;
//         justify-content: center;
//     }

//     @media screen and (max-width: 479px) {
//         margin-right: 0;
//     }

//     object {
//         /* object is used in order to avoid 404 images html and provide default image fallback  */
//         position: relative;
//         width: 24px;
//         height: 24px;
//         z-index: 2;
//         display: inline-block;
//         vertical-align: top;

//         &:last-child {
//             z-index: 1;
//             margin-left: -3px;
//         }
//     }
// }
.symbolMainInfoImages {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    //margin-right: 26px;

    img {
        width: 24px; // Adjust size as needed
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }

    // img:first-child {
    //     position: relative;
    //     z-index: 2;
    // }

    // img:last-child {
    //     position: absolute;
    //     left: 16px; // Controls the overlap; adjust as needed
    //     z-index: 1;
    // }

    // &.symbolMainInfoSingleImage img {
    //     position: relative;
    //     left: 0;
    // }
    &.symbolMainInfoSingleImage {
        // margin-right: 6px;
    }
}

.symbolMainInfoCell {
    min-width: 0;
}

.favContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.favoritesIcon {
    display: flex;
    height: 26px;
    width: 26px;
    background-repeat: no-repeat;
    background-position: center;
    color: #89909c;
    padding: 0;
    background-image: url("/images/star_grey.svg");

    &.favoritesIconChecked {
        color: goldenrod;
        background-image: url("/images/star_golden.svg");
        background-size: 16px;
    }
}

.searchResultsTitle {
    font-weight: 600;
    font-size: var(--mbl-font-size-xs);
    line-height: 16px;
    color: var(--trades-section-notice-title-mbl-color);
    padding: 16px 16px 8px;
    border-bottom: 1px solid var(--asset-list-table-border);
}

@keyframes item-up-animation {
    0% {
        transform: translateY(5rem);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
}

.assetsWrap {
    &.skipAnimation {
        .assetRow,
        .assetRowType2 {
            animation: none;
        }
    }
}

.assetRow,
.assetRowType2 {
    display: grid;
    grid-template-columns: 22px 44px 1fr 60px;
    column-gap: 8px;
    height: 56px;
    border-bottom: 1px solid var(--asset-list-table-border);
    padding: 8px 15px 8px 15px;
    background-color: var(--asset-list-row-bg);

    animation-name: item-up-animation;
    animation-duration: 0.6s;
    animation-fill-mode: backwards;

    &.singleImage {
        grid-template-columns: 22px 28px 1fr 60px;
    }

    &.assetRowWithOrders {
        border-bottom: none;
    }

    :global(.MuiCheckbox-root) {
        padding: 7px 0;
    }

    .symbolMainInfoName {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: var(--mbl-font-size-md);
        line-height: 20px;
        color: var(--asset-list-symbol-main-info-name-color);
    }

    .symbolMainInfoDescription {
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        display: flex;
        color: #9098ad;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .symbolMainInfoDescriptionText {
            padding-left: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .clock_icon,
    .clock_icon_red {
        display: inline-block;
        min-width: 16px;
        width: 16px;
        height: 16px;
        background-image: url("/images/clock_green.svg");
    }

    .clock_icon_red {
        background-image: url("/images/clock_red.svg");
    }

    .priceValue {
        color: var(--asset-list-symbol-main-info-name-color);
        font-weight: 500;
        font-size: var(--mbl-font-size-md);
        line-height: 20px;
    }

    .priceValueChangeBadge {
        display: inline-flex;
        justify-content: center;
        font-weight: 500;
        font-size: var(--mbl-font-size-xsm);
        line-height: 14px;
        border-radius: 5px;
        padding: 1px 10px;
        color: white;
        width: 48px;

        &.priceValueChangeUp {
            background-color: var(--action-buy);
        }

        &.priceValueChangeDown {
            background-color: var(--action-sell);
        }

        &.priceValueChangeNone {
            background-color: inherit;
            color: var(--text-primary);
            border: 1px solid var(--asset-list-table-border);
        }
    }
}

.assetRow {
    &.touched {
        background-color: var(--asset-list-row-touched-bg);
    }

    &.highlighted {
        background-color: var(--asset-list-row-highlighted-bg);
    }
}

.assetRowType2 {
    grid-template-columns: 22px 1fr 72px 72px;

    .priceChange {
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        margin-top: 4px;
    }

    .infoBlock {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;
        background: var(--asset-list-info-block-bg);
        border-radius: 5px;

        &__label {
            font-weight: 500;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            color: #9098ad;
            margin-bottom: 2px;
        }

        &__value {
            font-weight: 500;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            color: var(--asset-list-info-block-label-value);

            &.long_value {
                font-size: var(--desk-font-size-08);
            }
        }
    }
}

.assetOrdersList {
    position: relative;
    border-top: 1px solid var(--asset-list-order-top-border);

    &::before {
        z-index: 1;
        background: var(--bg);
        width: 11px;
        height: 11px;
        position: absolute;
        left: 19px;
        top: -6px;
        content: "";
        transform: rotate(45deg);
        border-right: 1px solid var(--asset-list-order-top-border);
        border-bottom: 1px solid var(--asset-list-order-top-border);
        border-bottom-right-radius: 2px;
    }
}

.successAlert,
.infoAlert {
    background: #16bb6f;
    color: #ffffff;
    height: 40px;
    padding: 9px 40px;
    font-weight: 500;
    font-size: var(--mbl-font-size-md);
    line-height: 20px;
    position: relative;

    &::before {
        position: absolute;
        content: url("/images/success_white.svg");
        left: 12px;
        top: 8px;
    }
}

.infoAlert {
    background: #007bff;
}

.assetOrdersItem {
    height: 40px;
    background: var(--asset-list-order-default-bg);
    border-bottom: 1px solid var(--asset-list-table-border);
    display: grid;
    grid-template-columns: 30px 71px 35px 1fr 56px;
    align-items: center;
    column-gap: 8px;
    padding: 0 16px 0 8px;

    &:last-child {
        border-bottom: none;
    }

    .btnContainer {
        display: flex;

        :not(:first-child) {
            margin-left: 8px;
        }

        .btnEdit,
        .btnDelete,
        .btnClose {
            background: var(--asset-list-order-btn-bg);
            box-shadow: var(--asset-list-order-btn-shadow);
            border-radius: 5px;
            width: 24px;
            height: 24px;
            display: inline-flex;

            &::before {
                content: "";
                display: block;
                width: 65%;
                height: 65%;
                margin: auto;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }

        .btnEdit {
            &::before {
                background-image: var(--asset-list-order-edit-url);
            }

            &.touched {
                background-color: var(--asset-list-order-btn-pressed-bg);

                &::before {
                    background-image: var(--asset-list-order-pressed-edit-url);
                }
            }
        }

        .btnDelete {
            &::before {
                background-image: var(--asset-list-order-delete-url);
            }

            &.touched {
                background-color: var(--asset-list-order-btn-pressed-bg);

                &::before {
                    background-image: var(--asset-list-order-pressed-delete-url);
                }
            }
        }

        .btnClose {
            &::before {
                margin: auto;
                background-image: var(--asset-list-order-x-url);
            }

            &.touched {
                background-color: var(--asset-list-order-btn-pressed-bg);

                &::before {
                    background-image: var(--asset-list-order-pressed-x-url);
                }
            }
        }
    }

    .buyIcon,
    .sellIcon,
    .alarmClock {
        margin-left: 5px;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 2px;
    }

    .sellIcon {
        background-image: var(--asset-list-order-graph-down-url);
    }

    .buyIcon {
        background-image: var(--asset-list-order-graph-down-url);
        transform: rotate(-90deg);
    }

    .alarmClock {
        background-image: var(--asset-list-order-alarm-clock-url);
    }

    .orderType {
        font-weight: 500;
        font-size: var(--mbl-font-size-md);
        line-height: 20px;
        color: var(--asset-list-order-default-color);

        &__small {
            font-weight: 500;
            font-size: var(--mbl-font-size-xsm);
            line-height: 14px;
            color: #9098ad;
        }
    }

    .orderLots {
        font-weight: 500;
        font-size: var(--mbl-font-size-xsm);
        line-height: 14px;
        text-align: center;
        color: var(--asset-list-order-default-color);
        background: var(--asset-list-order-lots-bg);
        border-radius: 5px;
        padding: 3px 6px;
        display: inline-block;
    }

    .profit,
    .loss,
    .pending_dark,
    .pending_light {
        position: relative;
        font-weight: 500;
        font-size: var(--mbl-font-size-xs);
        line-height: 16px;
        color: #16bb6f;
        padding-left: 28px;

        &::before {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: -4px;
            content: url("/images/Up2.svg");
        }
    }

    .loss {
        color: #f25555;

        &::before {
            content: url("/images/Down2.svg");
        }
    }

    .pending_dark,
    .pending_light {
        color: var(--asset-list-order-default-color);

        &::before {
            content: url("/images/Static.svg");
        }
    }

    .pending_light {
        &::before {
            content: url("/images/Static_light.svg");
        }
    }

    &.recentlyOpened {
        background: var(--asset-list-order-recently-opened-bg);
    }

    &.marketClosed {
        background: var(--asset-list-order-market-closed-bg);
        border-color: var(--asset-list-order-item-market-closed-border);

        .buyIcon,
        .sellIcon,
        .alarmClock {
            background-image: url("/images/graph_down_grey.svg");
        }

        .alarmClock {
            background-image: url("/images/alarmClock_grey.svg");
        }

        .orderType {
            color: #9098ad;
        }

        .orderLots {
            color: var(--asset-list-order-market-closed-lots-color);
            background: var(--asset-list-order-market-closed-lots-bg);
        }

        .profit,
        .loss,
        .pending {
            color: #9098ad;

            &::before {
                content: url("/images/Up2_grey.svg");
            }
        }

        .loss {
            &::before {
                content: url("/images/Down2_grey.svg");
            }
        }

        .btnContainer {
            .btnEdit,
            .btnDelete,
            .btnClose {
                background-color: var(--asset-list-order-market-closed-btn-bg);
            }

            .btnEdit {
                &::before {
                    background-image: var(--asset-list-order-market-closed-edit-url);
                }
            }

            .btnDelete {
                &::before {
                    background-image: var(--asset-list-order-market-closed-delete-url);
                }
            }

            .btnClose {
                &::before {
                    background-image: var(--asset-list-order-market-closed-x-url);
                }
            }
        }
    }
}

.priceChangeUp {
    color: var(--action-buy);
}

.priceChangeDown {
    color: var(--action-sell);
}
