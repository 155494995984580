.tableHeader_container {
    display: flex;
    flex-direction: column;
    z-index: 4;
    height: 46px;
}

@keyframes item-up-animation {
    0% {
        transform: translateY(5rem);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        filter: alpha(opacity=0);
    }

    100% {
        transform: translateX(0);
        opacity: 1;
        -ms-filter: none;
        filter: none;
    }
}

.table_row {
    display: grid;
    grid-template-columns: 134px 80px 80px 126px 28px 64px 206px 64px;

    animation-name: item-up-animation;
    animation-duration: 0.6s;
    animation-fill-mode: backwards;

    @media screen and (max-width: 1260px) {
        grid-template-columns: 134px minmax(70px, 80px) minmax(40px, 80px) minmax(120px, 126px) 28px;
    }

    &:hover {
        &.withHighlight {
            background-color: var(--asset-list-table-active-row-bg);
        }
    }

    &.withBorder {
        border-bottom: 1px solid var(--asset-list-table-item-border-color);
    }

    &.activeRow {
        height: 64px !important;
        background: var(--asset-list-table-active-row-bg);
    }

    .tableHeader_container.noAnimation & {
        animation: none;
    }
}

.expanded {
    @media screen and (max-width: 1680px) {
        grid-template-columns:
            134px minmax(70px, 80px) minmax(40px, 80px) minmax(120px, 126px) 28px minmax(40px, 64px)
            minmax(40px, 206px) minmax(35px, 64px);
    }
}

.tableHeader_column {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 44px;
    z-index: 3;

    font-weight: 600;
    font-size: 1.1rem;
    line-height: 130%;
    color: var(--asset-list-table-color);
    box-sizing: border-box;

    &.activeRow {
        height: 62px !important;
        padding-top: 6px;
    }

    &.cell_name {
        padding-left: 10px;

        &.activeRow {
        }
    }

    &.cell_price {
        align-items: center;
    }

    &.cell_change {
        align-items: center;
    }

    &.cell_hight_low {
        align-items: center;
    }

    &.cell_bear {
        align-items: center;
    }

    &.cell_bull {
        align-items: center;
    }

    &.cell_favorite {
        align-items: center;
        padding-right: 14px;
    }
}

.tableHeader_cell {
    display: flex;
    z-index: 3;
    font-family: var(--main-font-family);
    font-size: var(--desk-font-size-1-3);
    height: 40px;
    line-height: 15.6px;
    padding: 12px 0;
    word-break: break-all;
    font-style: normal;
    font-weight: 600;
    color: var(--asset-list-table-header-color);

    &.cell_name {
        padding-left: 16px;
        cursor: pointer;
    }

    &.cell_price {
        justify-content: center;
    }

    &.cell_change {
        justify-content: center;
        cursor: pointer;
    }

    &.cell_hight_low {
        justify-content: center;
    }

    &.cell_bear {
        justify-content: center;

        > img {
            transform: rotate(360deg) scaleX(-1);
        }
    }

    &.cell_bull {
        justify-content: center;

        > img {
            transform: rotate(360deg) scaleX(-1);
        }
    }
}

.tableContainer {
    padding: 0 !important;
    height: calc(100vh - 203px);
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;

    @-moz-document url-prefix() {
        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;
    }

    @media (max-width: 1366px) {
        height: calc(100% - 46px);
    }

    @-moz-document url-prefix() {
        @media (max-width: 1366px) {
            height: calc(100% - 98px);
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1260px) {
            height: calc(100% - 200px);
        }
    }

    @-moz-document url-prefix() {
        @media (max-width: 1130px) {
            height: calc(100% - 113px);
        }
    }
}

.noSearchResultsMode {
    @media (max-width: 1366px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.tableHead {
    position: relative;
    z-index: 3;

    :global(.MuiTableCell-root) {
        white-space: nowrap;
        font-family: var(--main-font-family);
        font-size: var(--desk-font-size-1-3);
        font-weight: 700;
        border-bottom: none;
        height: 26px;
        padding: 0;

        &:first-child {
            padding-left: 12px;
        }
    }

    .columnSort {
        cursor: pointer;
        user-select: none;
    }
}

.columnSortIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.3rem;
}

.tableBody {
    display: flex;
    height: calc(100vh - 203px);
    width: 100%;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;

    @-moz-document url-prefix() {
        overflow: hidden;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 1366px) {
            height: calc(100% - 50px);
        }
    }

    @media (max-width: 1366px) {
        height: calc(100% - 50px);
    }

    &:hover {
        &::-webkit-scrollbar {
            display: block;
        }
    }

    &::-webkit-scrollbar {
        width: 5px;
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(187, 201, 213, 0.3);
        border-radius: 2.5px;
        height: 107px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bg);
    }

    .tableCell {
        display: flex;
        flex-direction: column;
    }
}

.tableBody__light {
    &::-webkit-scrollbar-thumb {
        background: rgba(187, 201, 213, 1);
    }
}

.trades_table_opened {
    height: 100%;
    height: calc(100vh - 201px - var(--trades-tables-height));

    @media (max-width: 1366px) {
        height: 100% !important;
    }

    @-moz-document url-prefix() {
        // max-width: 1366px
        @media (max-width: 1366px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.4) !important;
        }

        @media (max-width: 1366px) and (max-height: 790px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.42) !important;
        }

        @media (max-width: 1366px) and (max-height: 750px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.45) !important;
        }

        @media (max-width: 1366px) and (max-height: 710px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.49) !important;
        }

        @media (max-width: 1366px) and (max-height: 685px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.49) !important;
        }

        @media (max-width: 1366px) and (max-height: 670px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.56) !important;
        }

        @media (max-width: 1366px) and (max-height: 630px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.65) !important;
        }

        // max-width: 1260px
        @media (max-width: 1260px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.66) !important;
        }

        @media (max-width: 1260px) and (max-height: 790px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.7) !important;
        }

        @media (max-width: 1260px) and (max-height: 750px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.72) !important;
        }

        @media (max-width: 1260px) and (max-height: 720px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.8) !important;
        }

        @media (max-width: 1260px) and (max-height: 680px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.85) !important;
        }

        @media (max-width: 1260px) and (max-height: 650px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.9) !important;
        }

        @media (max-width: 1260px) and (max-height: 630px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 2.05) !important;
        }

        // max-width: 1130px
        @media (max-width: 1130px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.8) !important;
        }

        @media (max-width: 1130px) and (max-height: 850px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.81) !important;
        }

        @media (max-width: 1130px) and (max-height: 790px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.84) !important;
        }

        @media (max-width: 1130px) and (max-height: 755px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.9) !important;
        }

        @media (max-width: 1130px) and (max-height: 720px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 1.95) !important;
        }

        @media (max-width: 1130px) and (max-height: 680px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 2.05) !important;
        }

        @media (max-width: 1130px) and (max-height: 650px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 2.5) !important;
        }

        @media (max-width: 1130px) and (max-height: 630px) {
            height: calc((100vh - 201px - var(--trades-tables-height)) * 2.05) !important;
        }
    }
}

.noResults {
    @media (max-width: 1366px) {
        height: 100%;
    }
}

.priceChangeUp {
    color: var(--action-buy);
    font-weight: 700;
}

.priceChangeDown {
    color: var(--action-sell);
    font-weight: 700;
}

.priceFreeze {
    color: var(--asset-list-table-color);
}

.emptyList {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    font-weight: 700;
    font-size: var(--desk-font-size-2-5);
    line-height: 30px;
    text-align: center;
    color: var(--asset-list-empty-list-color);

    &.tradesTablesOpened {
        height: calc(
            100vh - var(--trades-tables-height) - var(--footer-height) -
                var(--asset-list-active-search-toolbar-mbl-height) - 42px - 46px
        );

        @media (max-width: 1366px) {
            height: 100%;
        }

        @media (max-width: 1260px) {
            height: 100%;
        }
    }
}

.symbolMainInfoCell {
    display: flex;
    align-items: center;
}
.symbolMainInfoImages {
    display: flex;
    align-items: center;
    position: relative;
    width: 50px;

    img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    }

    img:first-child {
        position: relative;
        z-index: 2;
    }

    img:last-child {
        position: absolute;
        left: 16px; // Controls the overlap; adjust as needed
        z-index: 1;
    }

    &.symbolMainInfoSingleImage {
        margin-right: 8px;
        width: 32px;
    }

    &.symbolMainInfoSingleImage img {
        position: relative;
        left: 0;
        min-width: 24px;
    }
}
// .symbolMainInfoImages {
//     margin-right: 6px;
//     white-space: nowrap;

//     // object {
//     //     /* object is used in order to avoid 404 images html and provide default image fallback  */
//     //     position: relative;
//     //     width: 24px;
//     //     height: 24px;
//     //     z-index: 2;
//     //     display: inline-block;
//     //     vertical-align: top;

//     //     &:last-child {
//     //         z-index: 1;
//     //         margin-left: -6px;
//     //     }
//     // }
// }

// .symbolMainInfoSingleImage {
//     object {
//         &:last-child {
//             margin-left: 0;
//         }
//     }
// }

.symbolAvailabilityInfo {
    display: flex;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    width: 120px;
    margin-top: 5px;

    font-weight: 400;
    font-size: var(--desk-font-size-1-3);
    line-height: 150%;

    .symbolAvailabilityIcon {
        margin-right: 0.4rem;
    }
}

.favoritesIcon {
    display: flex;
    height: 26px;
    width: 26px;
    background-repeat: no-repeat;
    background-position: center;
    color: #89909c;
    padding: 0;
    background-image: url("/images/star_grey.svg");

    &.favoritesIconChecked {
        color: goldenrod;
        background-image: url("/images/star_golden.svg");
    }
}

.sentimentsHeadCell {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    width: 100%;
    z-index: 5;
    padding: 10px;
    pointer-events: none;
    gap: 4px;

    img {
        pointer-events: all;
        vertical-align: baseline;
        padding-bottom: 2px;
        cursor: pointer;

        @media (max-width: 1366px) {
            padding-bottom: 4px;
        }
    }
}

.sentimentsTooltip {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 275px;
    height: auto;
    background: #ffffff;
    box-shadow: 0 0 20px rgba(52, 56, 65, 0.505764);
    border-radius: 8.8px;
    white-space: normal;
    padding: 10px;

    h3 {
        font-weight: 700;
        font-size: var(--desk-font-size-1-8);
        line-height: 21px;
        margin-bottom: 10px;
        text-align: center;
        color: #343841;
    }

    @media screen and (max-width: 1560px) {
        transform: translateX(-80%);
    }

    .sentimentsTooltipBuy,
    .sentimentsTooltipSell {
        display: grid;
        grid-template-columns: 30px 1fr 14px;
        grid-gap: 2px;
        width: 230px;
        align-items: center;
        font-size: var(--desk-font-size-1-1);
        line-height: 15px;
        text-align: center;
        word-break: keep-all;
        margin-bottom: 16px;
        font-weight: 500;

        color: #343841;

        &:before {
            content: "";
            width: 28px;
            height: 8px;
            border-radius: 8px;
            background: var(--action-buy);
            flex-shrink: 0;
        }

        &:after {
            content: "";
            flex-shrink: 0;
            width: 25px;
            height: 25px;
            background: url("/images/arrow_up_circle.svg");
        }
    }

    .sentimentsTooltipSell {
        &:before {
            background: var(--action-sell);
        }

        &:after {
            transform: rotateZ(180deg);
        }
    }

    .sentimentsTooltipDisclaimer {
        text-align: left;
        font-weight: 500;
        font-size: var(--desk-font-size-1);
        line-height: 12px;
        color: #343841;
        word-break: keep-all;
    }

    .sentimentsHeadCell:hover & {
        display: flex;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 45px;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 10px;
    background: var(--intro-first-step-button-bg);
    border: none;

    &:hover {
        background: var(--intro-first-step-button-hover-bg);
    }

    .contentBtn {
        display: flex;
        align-items: center;
        gap: 13px;

        .resetIcon {
            min-width: 19px;
            height: 19px;
            background: var(--asset-list-favorite-reset-list-icon-url) no-repeat center;
        }

        .titleBtn {
            font-size: var(--desk-font-size-1-5);
            font-weight: 600;
            color: var(--intro-first-step-button-color);
        }
    }
}
